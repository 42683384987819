<template>
  <div class="main">
    <div class="banner">
      <img :src="bannerImg" width="100%" alt="授课证banner" />
    </div>
    <div class="content">
      <div class="tabs">
        <div class="ali_box">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            ref="ali"
            class="ali"
            :class="item.value == medalActive ? 'tabActive' : ''"
            @click="handleTabClick($event, item.value, index)"
          >
            {{ item.lable }}
          </div>
        </div>

        <div class="active_bar" :style="{ left: bar_let,width: bar_width+'px'}"></div>
      </div>
      <div class="cer_cont">
        <cer-list v-if="medalActive=='cer'"/>
        <cer-info v-else-if="medalActive=='info'"/>
        <div v-else>not data<div>
      </div>
    </div>
  </div>
</template>
<script>
const CerList=()=>import('./components/index/list.vue');
const CerInfo=()=>import('./components/index/info.vue');
	import {mapState} from "vuex";
export default {
  name: "cer",
  data() {
    return {
      bannerImg: require("@/assets/imgs/cer/index_banner_icon.png"),
      // tabs
      tabs: [
        { lable: "授课证", value: "cer" },
        { lable: "介绍", value: "info" },
      ],
      medalActive: "cer",
      bar_let:0,
      bar_width:60,
    };
  },
  created(){
			// 获取用户信息，如果有加载模块页面， 没有跳转登录页
			if(!this.proToken) {
				this.$router.push({
					path: "/VclassesCenterLogin",
					query: {
						navtype: 'certificate',
					}
				})
			}
		},
  components:{
    'cer-list':CerList,
    'cer-info':CerInfo
  },
  computed: {
			...mapState({
				proToken: state => state.indexStore.B_PRO_TOKEN,
			})
		},
  methods: {
    //一级tab切换
    handleTabClick(ev, val, index) {
      let ali = this.$refs.ali;
      let one_ali_x = ali[0].getBoundingClientRect().left; //第一个li距离视窗左边距离
      let active_ali_x = ali[index].getBoundingClientRect().left; //当前选中li距离视窗左边距离
      this.bar_let = `${active_ali_x - one_ali_x}px`;
      this.medalActive = val;
       //改变高亮条长度        
      if(index==ali.length-1){
        this.bar_width=40;
      }else{
        this.bar_width=60;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  background-color: #F8F9FA;
  .banner {
    width: 100%;
  }
  .content {
    margin-top: 31px;
    .tabs {
      width: 160px;
      height: 46px;
      position: relative;
      margin: 0 auto 34px auto;
      .ali_box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .ali {
          float: left;
          font-size: 20px;
          font-weight: bold;
          color: #606978;
          line-height: 28px;
          cursor: pointer;
          &:first-child {
            margin-left: 0 !important;
          }
          &:last-child {
            float: right;
            margin-right: 0 !important;
          }
        }
      }
      .tabActive {
        color: #5d5aed !important;
        font-weight: 600;
      }
      .active_bar {
        background-color: rgba(0, 0, 0, 0);
        background: url(../../assets/imgs/cer/activeIcon.png) top center no-repeat;
        background-size:auto 100%;
        height: 6px;
        width: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s;
      }
    }
  }
}
</style>